import { URL } from "../utils/Constant";

export const getUploadImages = async (headers, formData, setIsLoading, setError, getAIdata) => {
    setIsLoading((val) => ({ ...val, imageLoading: true }));
    try {
        const resp = await fetch(URL.ERICSION.UPLOAD_MULTIPLE_IMG, {
            method: "POST",
            body: formData,
            headers: headers,
        });
        if (resp.ok) {
            const data = await resp.json();
            if (data.status_code === 200) {
                const imageUrl = data.data[0];
                setError((val) => ({ ...val, imageError: "" }));
                getAIdata(imageUrl);
            } else {
                setError((val) => ({ ...val, imageError: data.message }));
            }

        } else {
            setError((val) => ({ ...val, imageError: "Internal Server Error. Please try again", }));

        }
    } catch {
        setError((val) => ({ ...val, imageError: "Internal Server Error. Please try again", }));

    }
    setIsLoading((val) => ({ ...val, imageLoading: false }));
};

export const getHazardAIData = async (headersList, imgURL, setIsLoading, setError, _hazardsdetected) => {
    setIsLoading((val) => ({ ...val, ppehazardLoading: true }));
    const formData = JSON.stringify({ pic_url: imgURL, "modeltype": "gen_ai" })
    try {
        const resp = await fetch(URL.ERICSION.GET_HAZARD_AI_DATA, {
            method: "POST",
            body: formData,
            headers: headersList,
        });
        if (resp.ok) {
            const data = await resp.json();
            if (data.status_code === 200) {
                setError((val) => ({...val, ppehazardLoading: ""}));
                _hazardsdetected.push(data.data);
            } else {
                setError((val) => ({...val,  ppehazardLoading: data.message }));
                _hazardsdetected = [];
            }

        } else {
            _hazardsdetected = [];
            setError((val) => ({
                ...val,
                ppehazardLoading: "Internal Server Error. Please try again",
            }));

        }
    } catch {
        _hazardsdetected = [];
        setError((val) => ({
            ...val,
            ppehazardLoading: "Internal Server Error. Please try again",
        }));

    }
    setIsLoading((val) => ({ ...val, ppehazardLoading: false }));
};

export const getPpeAIData = async (headersList, imgURL, setIsLoading, setError, _ppedetected) => {
    const formData = JSON.stringify({ pic_url: imgURL })

    setIsLoading((val) => ({ ...val, ppehazardLoading: true }));
    try {
        const resp = await fetch(URL.ERICSION.GET_ALL_PPE_AI_DATA, {
            method: "POST",
            body: formData,
            headers: headersList,
        });
        if (resp.ok) {
            const data = await resp.json();
            if (data.status_code === 200) {
                setError((val) => ({ ...val, ppehazardLoading: "" }));
                _ppedetected.push(data.data);
            } else {
                setError((val) => ({ ...val, ppehazardLoading: data.message }));
                _ppedetected = [];
            }
        } else {
            _ppedetected = [];
            setError((val) => ({ ...val, ppehazardLoading: "Internal Server Error. Please try again" }));
        }
    } catch {
        _ppedetected = [];
        setError((val) => ({ ...val, ppehazardLoading: "Internal Server Error. Please try again" }));
    }
    setIsLoading((val) => ({ ...val, ppehazardLoading: false }));
};

export const getTaskAssocoatHazardAIData = async (headersList, formData, setIsLoading, setErr, setTaskAssocoatHazardData) => {
    setIsLoading((val) => ({ ...val, taskAssocoatHazardData: true }));
    const _formData = JSON.stringify(formData);
    try {
        const resp = await fetch(URL.ERICSION.GET_HAZARD_AI_DATA, {
            method: "POST",
            body: _formData,
            headers: headersList,
        });
        if (resp.ok) {
            const data = await resp.json();
            if (data.status_code === 200) {
                setErr((val) => ({ ...val, taskAssocoatHazardData: "" }));
                setTaskAssocoatHazardData(data.data);
            } else {
                setErr((val) => ({ ...val, taskAssocoatHazardData: data.message }));
                setTaskAssocoatHazardData([]);
            }

        } else {
            setTaskAssocoatHazardData([]);
            setErr((val) => ({
                ...val,
                taskAssocoatHazardData: "Internal Server Error. Please try again",
            }));

        }
    } catch {
        setTaskAssocoatHazardData([]);
        setErr((val) => ({
            ...val,
            taskAssocoatHazardData: "Internal Server Error. Please try again",
        }));

    }
    setIsLoading((val) => ({ ...val, taskAssocoatHazardData: false }));
};